import React from "react";
import Layout from "../../layouts/index.jsx";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";

import "./Products.css";
import ScrollToTop from "../../components/scroll-to-top";

const Products1 = () => {
    return (
        <React.Fragment>
            <Layout>
                <div className="wrapper">
                    <Header />
                    <div>
                        <h1 data-aos="fade-up" className="head-pro">
                            Injectable
                        </h1>
                        <div className="head-pro1">
                            <div className="head-pro2">
                                <div className="head-flexi">
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Bolde 250
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/BOLDE 250.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <span className="head-pros">
                                                Each ml contains:
                                            </span>
                                            <div className="head-di2">
                                                <div className="head-di1">
                                                    <span>
                                                        Boldenone Undecylenate
                                                    </span>
                                                    <span className="pre-pre">
                                                        250 mg
                                                    </span>
                                                </div>
                                                <div className="head-di1">
                                                    <span>Oil Base</span>
                                                    <span className="pre-pre">
                                                        q . s
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Bulk Matrix 600
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/BULK MATRIX 600.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Boldenone
                                                            Undecylenate
                                                        </span>
                                                        <span className="pre-pre">
                                                            250 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>
                                                            Testosterone
                                                            Enanthate
                                                        </span>
                                                        <span className="pre-pre">
                                                            250 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>
                                                            Trenbolone
                                                            Hexahydrobenzylcarbonate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Deca 250
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/DECA 250.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Nandrolone Decanoate
                                                        </span>
                                                        <span className="pre-pre">
                                                            250 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Deca 400
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/DECA 400.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Nandrolone Decanoate
                                                        </span>
                                                        <span className="pre-pre">
                                                            300 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>
                                                            Nandrolone
                                                            Phenylpropionate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Masteron
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/MASTERON.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Drostanolone
                                                            Propionate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Nandro-p 100
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/NANDRO-P 100.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Nandrolone
                                                            Phenylpropionate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Primex 100
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/PRIMEX 100.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Methenolone
                                                            Enanthate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Ripped Matrix 400
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/RIPPED MATRIX.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Trenbolone Acetate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>
                                                            Drostanolone
                                                            Propionate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>
                                                            Testosterone
                                                            Propionate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>
                                                            Testosterone
                                                            Phenylpropionate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Susta 250
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/SUSTA 250.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Testosterone
                                                            Propionate
                                                        </span>
                                                        <span className="pre-pre">
                                                            30 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>
                                                            Testosterone
                                                            Phenylpropionate
                                                        </span>
                                                        <span className="pre-pre">
                                                            60 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>
                                                            Testosterone
                                                            Isocaproate
                                                        </span>
                                                        <span className="pre-pre">
                                                            60 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>
                                                            Testosterone
                                                            Decanoate
                                                        </span>
                                                        <span className="pre-pre">
                                                          100 mg
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Test-c 250
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/TEST-C 250.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Testosterone
                                                            Cypionate
                                                        </span>
                                                        <span className="pre-pre">
                                                            250 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Test-e 250
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/TEST-E 250.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Testosterone
                                                            Enanthate
                                                        </span>
                                                        <span className="pre-pre">
                                                            250 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Test-p 100
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/TEST-P 100.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Testosterone
                                                            Propionate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Tren-a 100
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/TREN-A 100.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Trenbolone Acetate
                                                        </span>
                                                        <span className="pre-pre">
                                                 100 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Tren-e 200
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/TREN-E 200.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Trenbolone Enanthate
                                                        </span>
                                                        <span className="pre-pre">
                                                            200 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Tren-h 100
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/TREN-H 100.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                            Trenbolone
                                                            Hexahydrobenzylcarbonate
                                                        </span>
                                                        <span className="pre-pre">
                                                            100 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>Oil Base</span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="head-pro3">
                                        <div>
                                            <span className="head-pros">
                                                Winstrol 100
                                            </span>
                                        </div>
                                        <div className="pro-imggd">
                                            <img
                                                src={require("../../assets/WellnessImages/WINSTROL 100.jpg")}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                        <div>
                                                <span className="head-pros">
                                                    Each ml contains:
                                                </span>
                                                <div className="head-di2">
                                                    <div className="head-di1">
                                                        <span>
                                                        Super Micronised Stanozolol
                                                        </span>
                                                        <span className="pre-pre">
                                                        100 mg
                                                        </span>
                                                    </div>

                                                    <div className="head-di1">
                                                        <span>
                                                        Water for Injection
                                                        </span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                    <div className="head-di1">
                                                        <span>
                                                      Excipients
                                                        </span>
                                                        <span className="pre-pre">
                                                            q . s
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default Products1;
