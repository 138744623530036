import React from "react";
import Layout from "../../layouts/index.jsx";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";


import ScrollToTop from "../../components/scroll-to-top";
const Products2 = () => {
    return (
        <React.Fragment>
            <Layout>
                <div className="wrapper">
                    <Header />
                    <div>
                        <h1 data-aos="fade-up" className="head-pro">
                           Oral
                        </h1>
                    </div>

                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default Products2;
