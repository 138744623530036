import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";

import Footer from "../layouts/footer";

import ScrollToTop from "../components/scroll-to-top";

const BlogPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal"></div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default BlogPage;
