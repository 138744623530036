import React, { useRef } from "react";

import emailjs from "@emailjs/browser";
import toast from 'react-hot-toast';

const ContactForm = () => {
  const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_umguxi7",
                "template_xkhubmf",
                form.current,
                "RY6g5sa_TIqx1Pg3F"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    toast.success("Successfully Submit");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <form
        ref={form}
        onSubmit={sendEmail}
            className="contact-form-wrapper"
            data-aos="fade-up"
            data-aos-duration="1200"
        >
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <input
              

                            className="form-control"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Your Name"
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email Address"
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                   

                            name="subject"
                            placeholder="Subject (optional)"
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group mb-0">
                        <textarea
                            name="message"
                            rows="5"
                            placeholder="Write your message here"
                        ></textarea>
                    </div>
                </div>
                <div className="col-md-12 text-center">
                    <div className="form-group mb-0">
                        <button
                        onClick={sendEmail}
                            className="btn btn-theme btn-block"
                            type="submit"
                        >
                            Send Message
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
