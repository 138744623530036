import React, { useRef } from "react";

import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

const Newsletter = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_umguxi7",
                "template_2jfyx0f",
                form.current,
                "RY6g5sa_TIqx1Pg3F"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    toast.success("Successfully Submit");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <div>
            <form
                ref={form}
                onSubmit={sendEmail}
                className="newsletter-form input-btn-group"
            >
                <input
                    id="mc-form-email"
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                />
                <button
                    className="btn btn-theme"
                    type="button"
                    onClick={sendEmail}
                >
                    <i className="icofont-long-arrow-right"></i>
                </button>
            </form>
        </div>
    );
};

export default Newsletter;
