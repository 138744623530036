import { useState } from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../logo";
import { AiOutlineClose } from "react-icons/ai";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";


const StyledOffCanvas = styled.aside`
    position: fixed;
    top: 0;
    left: ${(props) => (props.isOpen ? 0 : "-100%")};
    width: 300px;
    height: 100%;
    background: linear-gradient(181deg, #072342, #588ac0);
    z-index: 500;
    color: white;
    transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Mobilenav = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [products, setproducts] = useState(false);

    return (
        <div className={`header-middle mobile-sticky`}>
            <div className="container d-lg-none" style={{ padding: "20px" }}>
                <div className="row">
                    <div className="col-12">
                        <div className="header-middle-content">
                            <Link to={"/"}>
                                <div className="header-logo logos1">
                                    <Logo
                                        image={`${process.env.PUBLIC_URL}/img/logo.png`}
                                    />
                                </div>
                            </Link>

                            <div className=" d-lg-none">
                                <button
                                    onClick={() => setIsOpen(!isOpen)}
                                    // onClick={}
                                    className="offcanvas-toggle tab-opp"
                                >
                                    {isOpen ? (
                                        <AiOutlineClose
                                            style={{
                                                fontSize: "30px",
                                                color: "white",
                                            }}
                                            className="open-tab-mob"
                                            size={30}
                                        />
                                    ) : (
                                        <HiMenuAlt3
                                            style={{
                                                fontSize: "30px",
                                                color: "white",
                                            }}
                                            size={30}
                                            className="open-tab-mob"
                                        />
                                    )}
                                </button>
                                <div className="nav-mo">
                                    <div className="mobile-menu-nav header2">
                                        <div>
                                            <div className="trigger_logo">
                                                <div className="logo">
                                                    <Link to="section1"></Link>
                                                </div>
                                            </div>
                                            <StyledOffCanvas isOpen={isOpen}>
                                                <div className="head-mobile-menu">
                                                    <ul
                                                        className="ul-menu-mobile"
                                                        style={{
                                                            listStyle: " none",
                                                        }}
                                                    >
                                                        <li>
                                                            <div className="mobile-k-m">
                                                                <Link to="/User">
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="link-k"
                                                                    ></span>
                                                                </Link>
                                                            </div>
                                                        </li>

                                                        <ul
                                                            style={{
                                                                listStyle:
                                                                    " none",
                                                            }}
                                                            className="mobile-tab-ul"
                                                        >
                                                            <li className="list-font-ss">
                                                                <Link
                                                                    exact
                                                                    to="/"
                                                                >
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="creative_link linko"
                                                                    >
                                                                        Home
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    setproducts(
                                                                        !products
                                                                    )
                                                                }
                                                                className="list-font-ss"
                                                            >
                                                                <div
                                                                className="di-12"
                                                                >


                                                                    
                                                                    <span
                    style={{cursor:'pointer'}}
                                                                    
                                                                    className="creative_link linko">
                                                                        Products
                                                                    </span>
                                                                  <span className="updown-spa">  
                                                                    {products?(
                                                                 < MdOutlineKeyboardArrowUp

                                                       
                                                                 />


                                                                    ) :(

                                                                        < MdOutlineKeyboardArrowDown

                                                                  
                                                                        />

                                                                    )}

                                                                  </span>
                                                                </div>

                                                                {products && (
                                                                    <ul className="ul-pros">
                                                                        <li>
                                                                            <Link
                                                                                to={
                                                                                    "/Injectable"
                                                                                }
                                                                            >
                                                                                <span
                                                                                    onClick={() =>
                                                                                        setIsOpen(
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                    className="creative_link linko"
                                                                                >
                                                                                    Injectable
                                                                                </span>
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link
                                                                                to={
                                                                                    "/Oral"
                                                                                }
                                                                            >
                                                                                <span
                                                                                   onClick={() =>
                                                                                    setIsOpen(
                                                                                        false
                                                                                    )
                                                                                    }
                                                                                    className="creative_link linko"
                                                                                >
                                                                                    Oral
                                                                                </span>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li className="list-font-ss">
                                                                <Link to="Company-Profile">
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="creative_link linko"
                                                                    >
                                                                        Company
                                                                        Profile
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                            <li className="list-font-ss">
                                                                <Link to="News">
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="creative_link linko"
                                                                    >
                                                                        News
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                            <li className="list-font-ss">
                                                                <Link to="Contact">
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="creative_link linko"
                                                                    >
                                                                        Contact
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </StyledOffCanvas>
                                            <StyledOffCanvasOverlay
                                                isOpen={isOpen}
                                                onClick={() => setIsOpen(false)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mobilenav;
